import React from "react"
import { Link } from 'gatsby';
import Img from "gatsby-image"
import styled from "styled-components"
import { motion } from "framer-motion"
import useIntersectionObserver from "../../hooks/useIntersectionObserver"

const Grid = styled.section`
  padding-bottom: 5rem;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`

const FlexItem = styled.div`
  flex: 1 0 45%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 1028px) {
    justify-content: center;
  }
`
const Image = styled(Img)``

const Headline = styled.div`
  font-family: "Courier New", Courier, monospace;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  width: 30ch;
  padding: 2rem 1rem 1rem 1rem;
`

const P = styled.p`
font-size: 0.95rem;
  line-height: 2.25rem;
  max-width: 75ch;
  letter-spacing: 1.35px;
  padding: 0 1rem;
`

const CustomLink = styled(Link)`
  margin: 2rem 1rem;
  color: black;
  font-size: 1rem;
  display: inline-block;
`

// pick n schönes easing

const Einrichtungsberatung = ({ img, title, text }) => {
  // const { ref, visible } = useIntersectionObserver({
  //   root: null,
  //   rootMargin: "200px",
  //   threshold: 0.7, // 0.1 bis 1.0
  // })
  return (
    <Grid>
      <FlexItem>
        <Img fluid={img} style={{ width: "100%" }} />
      </FlexItem>
      <FlexItem>
        <div>
          <Headline>{title}</Headline>
          <P>{text}</P>
          <CustomLink to="/einrichtungsplanung">Mehr erfahren</CustomLink>
        </div>
      </FlexItem>
    </Grid>
  )
}

export default Einrichtungsberatung
