import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import useIntersectionObserver from "../../hooks/useIntersectionObserver"

const Grid = styled.section`
  padding-bottom: 5rem;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`

const FlexItem = styled(motion.div)`
  order: 2;
  flex: 1 0 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1028px) {
    order: 1;
  }
`

const PositionedFlexItem = styled(motion.div)`
  order: 1;
  position: relative;
  height: 50vh;
  flex: 1 1 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  @media (min-width: 1028px) {
    order: 2;
  }
`

const Accessoire = styled(motion.div)`
  position: absolute;
  top: ${props => props.top};
  left: ${props => props.left};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
`

const Image = styled(Img)`
  width: ${props => props.width};
  
`

const Headline = styled.div`
  font-family: "Courier New", Courier, monospace;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  width: 30ch;
  padding: 2rem 1rem 1rem 1rem;
`

const P = styled.p`
  font-size: 0.95rem;
  line-height: 2.25rem;
  max-width: 75ch;
  letter-spacing: 1.35px;
  padding: 0 1rem;
`

// pick n schönes easing

const variants = {
  hidden: {
    // opacity: 0,
  },
  visible: {
    // opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.5,
    },
  },
}

const childVariants = {
  hidden: {
    opacity: 0,
    y: -100,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
}

const Wohnaccessoires = ({ gallery, title, text }) => {
   const { ref, visible } = useIntersectionObserver({
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  })
  const controls = useAnimation()
  React.useEffect(() => {
    visible ? controls.start("visible") : controls.start("hidden")
  }, [controls, visible]);
  return (
    <Grid>
      <FlexItem>
        <div>
          <Headline>{title}</Headline>
          <P>{text}</P>
        </div>
      </FlexItem>
      <PositionedFlexItem
        initial="hidden"
        animate={controls}
        ref={ref}
        variants={variants}
      >
        <Accessoire
          top="0"
          left="0"
          bottom="unset"
          right="unset"
          variants={childVariants}
        >
          <Image fluid={gallery[0]} width="150px" />
        </Accessoire>

        <Accessoire
          top="0"
          left="unset"
          bottom="unset"
          right="0"
          variants={childVariants}
        >
          <Image fluid={gallery[1]} width="150px" />
        </Accessoire>

        <Accessoire
          top="unset"
          left="0"
          bottom="0"
          right="unset"
          variants={childVariants}
        >
          <Image fluid={gallery[2]} width="250px" />
        </Accessoire>

        <Accessoire
          top="unset"
          left="unset"
          bottom="0"
          right="0"
          variants={childVariants}
        >
          <Image fluid={gallery[3]} width="400px" />
        </Accessoire>
      </PositionedFlexItem>
    </Grid>
  )
}

export default Wohnaccessoires
