import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Hero from "../components/einrichtungshaus/Hero"
import Moebel from "../components/einrichtungshaus/Moebel"
import Wohnaccessoires from "../components/einrichtungshaus/Wohnaccessoires"
import Einrichtungsberatung from "../components/einrichtungshaus/Einrichtungsberatung"




const Einrichtungshaus = ({ data }) => {
  const content = data.allWpPage.nodes[0]
  const hero = content.pageTypeEinrichtungshaus.hero
  const m = content.pageTypeEinrichtungshaus.moebel
  const wa = content.pageTypeEinrichtungshaus.wohnaccessoires
  const eb = content.pageTypeEinrichtungshaus.einrichtungsplanung
  console.log(m.gallery)

  const sources = [
    hero.mobileimage.localFile.childImageSharp.fluid,
    {
      ...hero.heroimg.localFile.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  return (
    <Layout>

      <Hero
        img={sources}
        text={hero.welcomemessage}
      />

      <Moebel
        gallery={m.gallery.map(g => g.localFile.childImageSharp.fluid)}
        moebelTitle={m.moebel.title}
        moebelText={m.moebel.bodycopy}
        tischlereiTitle={m.tischlerei.title}
        tischlereiText={m.tischlerei.title}
      />

      <Wohnaccessoires
        gallery={wa.gallery.map(g => g.localFile.childImageSharp.fluid)}
        title={wa.title}
        text={wa.bodycopy}
      />

      <Einrichtungsberatung
        img={eb.img.localFile.childImageSharp.fluid}
        title={eb.title}
        text={eb.bodycopy}
      />
    </Layout>
  )
}

export default Einrichtungshaus

export const query = graphql`
  query MyQuery {
    allWpPage(filter: { title: { eq: "Einrichtungshaus" } }) {
      nodes {
        pageTypeEinrichtungshaus {
          hero {
            welcomemessage
            heroimg {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            mobileimage {
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          }
          moebel {
            moebel {
              title
              bodycopy
            }
            tischlerei {
              title
              bodycopy
            }
            gallery {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          wohnaccessoires {
            title
            bodycopy
            gallery {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          einrichtungsplanung {
            title
            bodycopy
            img {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
