import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { motion, useAnimation } from "framer-motion"
import useIntersectionObserver from "../../hooks/useIntersectionObserver"

const Grid = styled.section`
  padding-bottom: 5rem;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    "pic1"
    "text1"
    "pic4"
    "text2";

  // Tablet
  @media (min-width: 768px) {
    grid-template-areas: "";
  }
  // Desktop
  @media (min-width: 1028px) {
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 2rem;
    grid-template-areas:
      "pic1 pic1 pic1 pic1 pic1 pic1 pic1 pic1 pic2 pic2 pic2 pic2"
      "text1 text1 text1 text1 text1 pic3 pic3 pic3 pic3 pic3 pic3 pic3"
      "pic4 pic4 pic4 pic4 pic4 pic4 pic4 text2 text2 text2 text2 text2";
  }
`

//props.children für gridItem

const GridItem = styled.div`
  display: ${props => (props.show === "no" ? "none" : "flex")};
  grid-area: ${props => props.gridArea};
  /* background-color: ${props => props.backgroundColor}; */
  justify-content: center;
  align-items: center;
  @media (min-width: 1028px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const TextContainer = styled.div`
  
`

const Image = styled(Img)``

const Headline = styled.div`
  font-family: "Courier New", Courier, monospace;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  width: 30ch;
  padding: 1rem;
`

const P = styled.p`
  font-size: 0.95rem;
  line-height: 2.25rem;
  max-width: 75ch;
  letter-spacing: 1.35px;
  padding: 1rem;
`

const Moebel = ({ gridArea, gallery, moebelTitle, moebelText }) => {
 
  return (
    <Grid>
      <GridItem gridArea="pic1" backgroundColor="turquoise">
        <Img fluid={gallery[0]} style={{ width: "100%" }} />
      </GridItem>

      <GridItem show="no" gridArea="pic2" backgroundColor="beige">
        <Img fluid={gallery[1]} style={{ width: "100%" }} />
      </GridItem>

      <GridItem gridArea="text1" backgroundColor="white">
        <TextContainer>
          <Headline>{moebelTitle}</Headline>
          <P>{moebelText}</P>
        </TextContainer>
      </GridItem>

      <GridItem show="no" gridArea="pic3" backgroundColor="red">
        <Img fluid={gallery[2]} style={{ width: "100%" }} />
      </GridItem>

      <GridItem gridArea="pic4" backgroundColor="brown">
        <Img fluid={gallery[3]} style={{ width: "100%" }} />
      </GridItem>

      <GridItem gridArea="text2" backgroundColor="white">
        <TextContainer>
          <Headline>Tischlerei</Headline>
          <P>
            Übrigens gehört zu unserem Einrichtungshaus auch eine eigene
            Tischlerei. So können wir auch außergewöhnliche Möbelideen für
            individuelles Design umsetzen. Wir können Sofas, Regale und Tische
            nach Ihren Maßen und persönlichen Vorstellungen gestalten.{" "}
          </P>
        </TextContainer>
      </GridItem>
    </Grid>
  )
}

export default Moebel
