import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import IFrame from "../iFrame"

// learn flex-wrap wtf.

const HeroSection = styled.section`
  height: 100%;
  @media(min-width: 1028px) {
       /* height: 91.5vh; */
       height: 100%;
   }

`
// change into gatsby img
const ImageWrapper = styled.div`
  width: 100%;
  /* position: absolute;
  top: 0;
  left: 0; */
`

// evtl lieber justify-content + max-width auf child. aber keine ahnung, ob sowas funktioniert.

const FlexContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  height: 100%;
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
  
`

const FlexItem = styled.div`
  flex: ${props => props.flex};
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2rem;
  padding: 0 1rem;
  @media(min-width: 800px) {
    align-items: center;
   }
   @media(min-width: 1028px) {
       margin-top: 0;
   }
`

const Title = styled.h1`
  font-family: "Courier New", Courier, monospace;
  font-size: 1.5rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
  width: 100%;
`

const TitleUnderline = styled.div`
  width: 100%;
  height: 3.5px;
  border-radius: 150vh;
  background-color: #58595b;
  margin-bottom: 0.5rem;
`

const LeftFlexContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`

const LeftFlexItem = styled.div`
   flex: ${props => props.flex};
`

const MiniP = styled.p`
  font-size: .75rem;
  line-height: 1.75rem;
`

const P = styled.p`
  /* padding: 1rem 3rem 1rem 1rem; */
  font-size: .9rem;
  line-height: 2.5rem;
  width: 100%;
  @media(min-width: 1028px) {
    max-width: 71ch;
  }
`

const Hero = (props) => {
  return (
    <HeroSection>
      {/* add mobile image */}
      <IFrame img={props.img} />
      <FlexContainer>
        <FlexItem flex="1 0 100%">
          <div>
            <Title>Das kreative Möbelhaus in Düsseldorf!</Title>
            <TitleUnderline />

            <LeftFlexContainer>
              <LeftFlexItem
              flex="1 1 250px"
              >
                <div style={{marginBottom: "1rem"}}>
                <MiniP><strong>Immermannstraße 6</strong></MiniP>
                <MiniP><strong>40210 Düsseldorf</strong></MiniP>
                </div>
              </LeftFlexItem>

              <LeftFlexItem
              flex="1 1 250px"
              >
                <MiniP><strong>Öffnungszeiten:</strong></MiniP>
                <MiniP><strong>Dienstag bis Freitag:</strong> 10:30 bis 19:00 Uhr</MiniP>
                <MiniP><strong>Samstag:</strong> 10:30 bis 18:00 Uhr</MiniP>
                <MiniP><strong>Montag und Sonntag:</strong> geschlossen</MiniP>
                
              </LeftFlexItem>
            </LeftFlexContainer>
          </div>
        </FlexItem>
        <FlexItem flex="1 0 100%">
          {/* source text from wordpress */}
          <P>
           {props.text}
          </P>
        </FlexItem>
      </FlexContainer>
    </HeroSection>
    // Modal mit dem Vollbild iFrame darin.
  )
}

export default Hero
