import * as React from "react"

// options are (root, rootMargin, threshold I pass)

function useIntersectionObserver(options) {
  const [visible, setVisible] = React.useState(false)
  const ref = React.useRef(null)

  React.useEffect(() => {

    // first Argument is the Callback function

    const observer = new IntersectionObserver(entries => {
      const [entry] = entries
      entries.forEach(entry => {
        setVisible(entry.isIntersecting)
      })
    }, options)
    
    if (ref.current) observer.observe(ref.current)
    // this function is a cleanup function that only works when used with useEffect.
    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [ref, options])

  return {
    visible,
    ref,
  }
}

export default useIntersectionObserver